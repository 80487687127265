import Home from '@/views/Home.vue'
import Admin from '@/views/Admin.vue'
import { createRouter, createWebHashHistory } from 'vue-router'

const routes: any[] = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/sifu-dream01',
    name: 'admin',
    component: Admin
  }

  //   {
  //     path: '/discover',
  //     name: 'discover',
  //     component: Discover
  //   },
  //   {
  //     path: '/about',
  //     name: 'about',
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
