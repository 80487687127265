import { defineStore } from 'pinia'

export const useInfoStore = defineStore('info', {
  state: () => ({
    infos: {} as any
  }),

  getters: {
    getStationsInfo: (state) => state.infos
  },

  actions: {
    setStationsInfo(info: any) {
      this.infos = info
    }
  }
})
