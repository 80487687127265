<template>
  <!-- <v-dialog v-model="showEdit" max-width="800px"> -->
  <v-card>
    <v-card-title class="d-flex">
      Edit
      <v-spacer></v-spacer>
      <v-btn variant="text" icon="mdi-close" class="ml-3" @click="closeDialog"></v-btn>
    </v-card-title>
    <v-card-text>
      <form>
        <v-row>
          <v-col cols="9">
            <v-text-field variant="solo" label="Display Name" v-model="itemToEdit.name">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field variant="solo" label="Sort Id" v-model="itemToEdit.sortId">
            </v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          variant="solo"
          label="Stream Url"
          v-model="itemToEdit.streamUrl"
        ></v-text-field>
        <v-text-field variant="solo" label="Logo Url" v-model="itemToEdit.logoUrl"></v-text-field>
        <v-text-field variant="solo" label="Info Url" v-model="itemToEdit.infoUrl"></v-text-field>
        <v-checkbox v-model="itemToEdit.hidden" label="Hidden"></v-checkbox>

        <v-textarea
          rows="3"
          variant="solo"
          label="Description"
          v-model="itemToEdit.description"
        ></v-textarea>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="me-2 text-none"
        prepend-icon="mdi-floppy"
        variant="flat"
        color="primary"
        @click="saveItem(itemToEdit)"
      >
        Save
      </v-btn>
      <v-btn class="me-2 text-none" variant="flat" color="secondary" @click="closeDialog">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
  <!-- </v-dialog> -->
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { station } from '@/types/station'
export default {
  props: {
    item: {
      type: Object as PropType<station>,
      required: true
    },

    modelValue: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showEdit: false,
      itemToEdit: {} as station
    }
  },

  methods: {
    closeDialog() {
      this.showEdit = false
      this.$emit('update:modelValue', this.showEdit)

      this.$emit('onCloseClicked')
    },

    saveItem(item: station) {
      this.closeDialog()
      this.$emit('onSaveClicked', item)
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      handler: function (v) {
        this.showEdit = v
      }
    },

    showEdit: {
      handler: function (v) {
        this.$emit('update:modelValue', this.showEdit)
      }
    },

    item: {
      deep: true,
      immediate: true,
      handler: function (v) {
        this.itemToEdit = v
      }
    }
  }
}
</script>
