<template>
  <div class="player">
    <audio id="player"></audio>

    <div class="info">
      {{ store.currentStation.name }} <span class="playing-text">{{ nowPlaying }}</span>
    </div>
    <v-spacer></v-spacer>
    <v-btn
      size="small"
      color="#fff"
      variant="text"
      @click="togglePlay"
      :icon="isPlaying ? 'mdi-stop' : 'mdi-play'"
    >
    </v-btn>

    <div style="width: 200px" class="mr-3">
      <v-slider v-model="volume" color="#fff" hide-details density="compact"></v-slider>
    </div>

    <v-btn
      size="small"
      color="#B0BEC5"
      variant="text"
      @click="toggleMuteAudio"
      :icon="isMutted ? 'mdi-volume-mute' : 'mdi-volume-high'"
    >
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.player {
  display: flex;
  width: 100%;
  align-items: center;
  font-family: 'ubuntu-mono', monospace;
}

.player .info {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4px;
}

.playing-text {
  color: #ffeb3b;
}
</style>

<script lang="ts">
import { usePlayerStore } from '@/stores/player'
import { onMounted, watch, reactive, ref, computed } from 'vue'

export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const store = usePlayerStore()

    let isMutted = ref(false)
    let isPlaying = ref(false)

    let audio: HTMLAudioElement
    let volume = ref(50)

    let playerState = reactive({
      isPlaying: false,
      isMutted: false,
      volume: 0
    })

    let setPlayerState = () => {
      playerState.isPlaying = isPlaying.value
      playerState.isMutted = isMutted.value
      playerState.volume = volume.value

      store.setPlayerState(reactive(playerState))
    }

    onMounted(() => {
      audio = document.getElementById('player') as HTMLAudioElement
      store.playerElem = audio
    })

    watch(
      () => props.src,
      async (v) => {
        if (audio != null) {
          try {
            audio.pause()
            audio.volume = volume.value / 100
            audio.src = v
            await audio.play()
            isPlaying.value = true
          } catch {
            audio.pause()
            isPlaying.value = false
          }

          setPlayerState()
        }
      }
    )

    watch(
      () => store.currentStation,
      (v: any) => {
        console.log(v)
      }
    )

    watch(
      () => store.playerState,
      (v) => {
        volume.value = v.volume
      }
    )

    watch(volume, () => {
      audio.volume = volume.value / 100
      isMutted.value = false
      setPlayerState()
    })

    let toggleMuteAudio = () => {
      isMutted.value = !isMutted.value
      audio.volume = isMutted.value ? 0 : volume.value / 100

      setPlayerState()
    }

    let togglePlay = () => {
      isPlaying.value = !isPlaying.value
      try {
        isPlaying.value ? audio.play() : audio.pause()
      } catch (err) {
        console.log('cannot apply action', err)
      }

      setPlayerState()
    }

    let nowPlaying = computed(() => {
      if (!store.playing || store.playing?.trim().length == 0) {
        return ''
      }

      return `${store.playing}`
    })

    return {
      store,
      toggleMuteAudio,
      togglePlay,
      isMutted,
      isPlaying,
      volume,
      nowPlaying
    }
  }
}
</script>
