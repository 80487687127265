import { defineStore } from 'pinia'
import { usePlayerStore } from './player'

export interface IAppSettings {
  [index: string]: any
}

const LOCAL_STORAGE_KEY = 'dream01:appsettings'

export const useAppSettingsStore = defineStore('appSettings', {
  state: () => ({
    appSettings: {} as IAppSettings
  }),

  getters: {
    getAppSettings: (state) => state.appSettings as IAppSettings
  },

  actions: {
    setAppSettings(settings: IAppSettings) {
      this.appSettings = settings
    },

    setAppSetting(key: string, value: any) {
      this.appSettings[key] = value
      this.saveSettings()
    },

    loadSettings() {
      const playerStore = usePlayerStore()

      let s = localStorage.getItem(LOCAL_STORAGE_KEY)
      if (s == null) {
        s = '{ "version" : "1"}'
        localStorage.setItem(LOCAL_STORAGE_KEY, s)
      }

      try {
        let p = JSON.parse(s)
        Object.keys(p).map((x) => {
          switch (x) {
            case 'currentStation':
              playerStore.setCurrentStation(p[x])
              break
            case 'playerState':
              playerStore.setPlayerState(p[x])
              break
          }
          this.setAppSetting(x, p[x])
        })

        playerStore.playerState.isPlaying = false
      } catch {
        localStorage.setItem(LOCAL_STORAGE_KEY, '{ "version" : "1"}')
      }
    },

    saveSettings() {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.appSettings))
    }
  }
})
