import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useAppSettingsStore } from './appSettings'
import { useWebsocketStore } from './websocket'

export const usePlayerStore = defineStore('player', {
  state: () => ({
    playerElem: {} as any,
    playerState: {} as any,
    source: '',
    currentStation: {
      id: 0,
      name: 'Click any station to tune in...',
      streamUrl: ''
    },
    playing: ''
  }),

  getters: {
    getCurrentStation: (state) => state.currentStation,

    getPlayerState: (state) => state.playerState
  },

  actions: {
    setCurrentStation(station: any) {
      this.currentStation = station

      let s = useAppSettingsStore()
      s.setAppSetting('currentStation', station)

      const ws = useWebsocketStore()
      ws.send(`TUNE:${station.id}`)
    },

    setPlayerState(playerState: any) {
      this.playerState = playerState

      let s = useAppSettingsStore()
      s.setAppSetting('playerState', playerState)
    },

    setPlaying(title: string) {
      this.playing = title
    },

    startPlayer() {
      this.playerElem.play()
    }
  }
})
