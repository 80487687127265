import './assets/base.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import router from '@/router'

const app = createApp(App)

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents
  },
  directives,
  aliases,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#0D47A1',
          secondary: '#ECEFF1'
        }
      }
    }
  }
})

app.use(createPinia())
app.use(vuetify)
app.use(router)

app.mount('#app')
