<template>
  <div>
    <v-card width="400" v-if="!userInfo.isAuthenticated">
      <v-card-title> Login </v-card-title>
      <v-card-text>
        <v-text-field
          label="Username"
          variant="solo"
          density="compact"
          v-model="username"
        ></v-text-field>
        <v-text-field
          :disabled="!username.length"
          label="Password"
          variant="solo"
          density="compact"
          type="password"
          v-model="password"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="doLogin">login</v-btn>
      </v-card-actions>
    </v-card>
    <div v-else>
      <ListStations></ListStations>
    </div>
  </div>
</template>

<script lang="ts">
import { useUserStore } from '@/stores/user'
import { mapState, mapActions } from 'pinia'
import ListStations from '@/components/admin/ListStations.vue'

export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },

  components: {
    ListStations
  },

  computed: {
    ...mapState(useUserStore, ['userInfo'])
  },

  methods: {
    ...mapActions(useUserStore, ['login']),

    async doLogin() {
      try {
        await this.login(this.username, this.password)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
