<template>
  <v-app theme="light">
    <!-- <div :class="animation"></div> -->
    <v-app-bar app height="88" color="#272727" class="main-toolbar">
      <template v-slot:prepend>
        <v-img src="@/assets/logo-small-clean1.png"></v-img>
      </template>
      <v-app-bar-title>
        <a class="no-style" href="#" to="/">dream01</a>
      </v-app-bar-title>
    </v-app-bar>

    <v-main class="main-content">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app color="#272727">
      <player :src="currentStation.streamUrl"></player>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
$color-green-elliott: #0a2647;
$color-horned-frog: #144272;
$color-minstrel-rose: #205295;
$color-waiting: #2c74b3;
$color-opal-violet: #40627d;
$color-blue-thistle: #aebfd7;

.main-content {
  background-color: #e0e0e0;
}

.router-link-active {
  background-color: #eee;
}

.main-toolbar {
  font-family: 'mrrobot';
}

.main-toolbar div {
  font-size: 34px;
  font-weight: normal;
  color: #eeeeee;
  line-height: normal;
}

.player-bar {
  font-family: 'consolas';
}

.stations {
  display: flex;
  flex-wrap: wrap;
  font-family: 'ubuntu-reg';
}

.logo {
  margin-top: -30px;
}

.no-background {
  background-color: unset;
}

.no-style {
  color: unset;
  text-decoration: none;
}

.no-style:visited {
  color: unset;
}

.op1 {
  opacity: 1;
}

.op2 {
  opacity: 0;
}

.op3 {
  opacity: 0.2;

  content: '';
  background: url('@/assets/f/f9.jpg');

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 0;

  background-size: cover;
  transition: all 10s ease-in;
  background-repeat: no-repeat;
  background-position: center;
}

.ani1::before {
  content: '';
  background: url('@/assets/discoball.gif');
  background-position: center;
  opacity: 0.1;
  top: 45px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;

  background-size: cover;
  transition: all 4s ease-in;
  background-repeat: no-repeat;
}

.ani2::before {
  content: '';
  background: url('@/assets/playing_slow2.gif');
  background-position: center;
  opacity: 0.1;
  top: 45px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;

  background-size: contain;
  transition: all 4s ease-in;
}

.ani3::before,
.ani4::before,
.ani5::before,
.ani6::before,
.ani7::before,
.ani8::before,
.ani9::before,
.ani10::before {
  content: '';
  background-position: center;
  opacity: 0.1;
  top: 45px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;

  background-size: contain;
  transition: all 4s ease-in;
}

.layer {
  content: '';
  background: url('@/assets/discoball.gif');

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 0;

  background-size: cover;
  transition: all 10s ease-in;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 628px) {
  .stations {
    flex-direction: column;
  }
}
</style>

<script setup lang="ts"></script>

<script lang="ts">
import Station from '@/components/Station.vue'
import Player from './components/Player.vue'
import axios from 'axios'
import { mapActions, mapState } from 'pinia'
import { usePlayerStore } from '@/stores/player'
import { useWebsocketStore } from '@/stores/websocket'
import { useAppSettingsStore } from './stores/appSettings'
import { useInfoStore } from './stores/info'
import { useUserStore } from './stores/user'

export default {
  data() {
    return {
      animation: 'layer op1'
    }
  },

  components: {
    Station,
    Player
  },

  async mounted() {
    let client = `${Date.now()}`
    var ws = new WebSocket(
      `${location.protocol === 'https:' ? 'wss' : 'ws'}://${location.host}/ws?id=${client}`
    )
    this.setWS(ws, client)

    // move
    ws.onopen = function () {
      ws.send('hello')
    }

    this.loadSettings()
    await this.startInfoMonitor()

    this.checkSessionStorage()
  },

  computed: {
    ...mapState(usePlayerStore, ['currentStation'])
  },

  methods: {
    ...mapActions(useWebsocketStore, ['setWS']),
    ...mapActions(useAppSettingsStore, ['loadSettings']),
    ...mapActions(useInfoStore, ['setStationsInfo']),
    ...mapActions(useUserStore, ['checkSessionStorage']),

    async startInfoMonitor() {
      let res = await axios.get(`/api/stations/info`)
      this.setStationsInfo(res.data)

      setInterval(async () => {
        let res = await axios.get(`/api/stations/info`)
        this.setStationsInfo(res.data)
      }, 30000)
    }
  }
}
</script>
