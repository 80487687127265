import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useWebsocketStore = defineStore('websocket', {
  state: () => ({
    ws: {} as WebSocket,
    client: ''
  }),

  getters: {
    getWS: (state) => state.ws
  },

  actions: {
    setWS(ws: WebSocket, client: string) {
      this.ws = ws as any
      this.client = client
    },

    send(msg: string) {
      this.ws.send(msg)
    }
  }
})
