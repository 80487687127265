<template>
  <div class="stations">
    <station v-for="r in (radios as any) " :key="r.id" :item="r"></station>
  </div>
</template>

<script lang="ts">
import Station from '@/components/Station.vue'
import axios from 'axios'
import { mapActions, mapState } from 'pinia'
import { usePlayerStore } from '@/stores/player'
import { useWebsocketStore } from '@/stores/websocket'

export default {
  data() {
    return {
      radios: null
    }
  },

  components: {
    Station
  },

  async mounted() {
    let res = await axios.get('/api/radios')
    this.radios = res.data
  },

  computed: {
    ...mapState(usePlayerStore, ['currentStation'])
  }
}
</script>
