import axios from 'axios'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {
      isAuthenticated: false,
      token: '',
      username: ''
    }
  }),

  getters: {},

  actions: {
    checkSessionStorage() {
      let user = sessionStorage.getItem('user')
      let token = sessionStorage.getItem('token')
      if (user && token) {
        this.userInfo.isAuthenticated = true
        this.userInfo.username = user
        this.userInfo.token = token
      }
    },

    async login(username: string, password: string) {
      let res = await axios.post('/auth', { username, password })
      if (res.data.token) {
        this.userInfo.isAuthenticated = true
        this.userInfo.token = res.data.token
        this.userInfo.username = res.data.username

        sessionStorage.setItem('user', res.data.username)
        sessionStorage.setItem('token', res.data.token)
      }
    }
  }
})
