<template>
  <v-card class="station" @click="tune(item)" :class="{ playing: isCurrentStation }">
    <v-card-title class="subtitle-2" :class="{ selected: isCurrentStation }" :title="item.name">
      {{ item.name }}
    </v-card-title>

    <v-card-text>
      <div class="thumb-logo">
        <v-img height="80" :src="getLogo(item)" alt="logo"></v-img>
      </div>
    </v-card-text>

    <v-card-title class="station-info">{{ playing }}</v-card-title>
  </v-card>
</template>

<style lang="scss">
@media screen and (max-width: 628px) {
  .station {
    width: 100% !important;
    height: 170px;
    margin: 10px;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
}

.station {
  width: 270px;
  height: 170px;
  margin: 5px;
}

.station-info {
  clear: both;
  font-size: 14px !important;
  color: #757575;
}

.thumb-logo {
  float: left;
}

.playing::after {
  content: '';
  //background: url('@/assets/playing.gif');
  background: url('@/assets/discoball.gif');
  background-position: center;
  opacity: 1;
  top: 45px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.4s ease-in;
  background-color: #f48fb1;
  background-blend-mode: luminosity;
}

.playing:hover::after {
  // background-image: url("~@/assets/playing.gif");
  opacity: 0.25;
  transition: all 0.4s ease-in;
  // background: url('@/assets/playing_slow.gif');
  background: url('@/assets/discoball.gif');
  top: 45px;
  background-position: center;
  background-color: dodgerblue;
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

<script lang="ts">
import '@/assets/playing.gif'
import defaultImg from '@/assets/default-thumb.png'
import { mapActions, mapState } from 'pinia'
import { usePlayerStore } from '@/stores/player'
import { useInfoStore } from '@/stores/info'
import { reactive } from 'vue'

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          fid: -1,
          name: 'station 1',
          sUrl: 'http://dream01.gr:8000/;'
        }
      }
    }
  },

  data() {
    return {
      playing: ''
    }
  },

  async mounted() {
    // let interval = Math.random() * 10 * 1000 + 20000
    // console.log(this.item.name, interval)
    // await this.getInfo()
    // setInterval(this.getInfo, interval)
  },

  computed: {
    ...mapState(usePlayerStore, ['currentStation', 'playerState']),
    ...mapState(useInfoStore, ['infos']),

    isCurrentStation() {
      return this.currentStation.id === this.item.id
    }
  },

  methods: {
    ...mapActions(usePlayerStore, [
      'setCurrentStation',
      'setPlaying',
      'setPlayerState',
      'startPlayer'
    ]),

    getLogo(r: any) {
      let url = r.logoUrl.trim()
      if (url.length === 0) {
        let u = new URL(defaultImg, import.meta.url)
        return u.href
      }
      return url
    },

    tune(r: any) {
      if (this.isCurrentStation) {
        // emit an event to start play if not already
        this.startPlayer()
      }

      this.setCurrentStation(r)
      this.setPlaying(this.playing)
    }
  },

  watch: {
    infos: {
      handler: function (v) {
        try {
          this.playing = v[this.item.id].playing
        } catch (err) {
          this.playing = ''
        }

        if (this.isCurrentStation) {
          this.setPlaying(this.playing)
        }
      }
    }
  },

  filters: {
    normalize(s: string) {
      const regex = /(\w|\.|,){18,}/gm
      var res = s.replace(regex, '_')

      if (res.length >= 25) {
        return res.substring(0, 24) + '_'
      }
      return res
    }
  }
}
</script>
