<template>
  <v-toolbar density="compact">
    <v-btn
      prepend-icon="mdi-plus"
      color="green"
      class="me-2 text-none"
      variant="flat"
      @click="createNewStation"
    >
      Create new entry
    </v-btn>
    <v-spacer></v-spacer>
    <!-- <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn> -->
  </v-toolbar>

  <div class="list-stations">
    <EditStation
      v-if="isEditing"
      :item="itemToEdit"
      @onSaveClicked="onItemSaveClicked"
      @onCloseClicked="onCloseClicked"
    >
    </EditStation>
    <div v-else>
      <!-- <EditStation v-model="showDialog" :item="itemToEdit" @onSaveClicked="onItemSaveClicked">
    </EditStation> -->
      <v-data-table
        width="100%"
        :items-per-page="-1"
        density="compact"
        :items="stations"
        :headers="headers"
      >
        <template v-slot:item.hidden="data">
          {{ data.item.raw.hidden }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="me-2"
            size="small"
            variant="plain"
            icon="mdi-pencil"
            @click="editItem(item.raw)"
            color="primary"
          ></v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-stations {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
</style>

<script lang="ts">
import { type station } from '@/types/station'
import axios from 'axios'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import EditStation from './EditStation.vue'
import { cloneDeep, create } from 'lodash'
export default {
  components: {
    EditStation
  },

  data() {
    return {
      stations: [] as Array<station>,
      headers: [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'hidden', title: 'is hidden' },
        { key: 'sortId', title: 'SortID' },
        { title: 'Actions', key: 'actions', sortable: false }
      ],
      itemToEdit: {} as station,
      showDialog: false,
      isEditing: false
    }
  },

  async mounted() {
    await this.loadStations()
  },

  computed: {
    ...mapState(useUserStore, ['userInfo'])
  },

  methods: {
    async loadStations() {
      let res = await axios.get(`/admin/stations`, {
        headers: {
          'x-auth-token': this.userInfo.token
        }
      })
      this.stations = res.data
    },

    editItem(item: any) {
      this.itemToEdit = cloneDeep(item) as station
      // this.showDialog = true
      this.isEditing = true
    },

    async onItemSaveClicked(item: station) {
      item.sortId = parseInt(`${item.sortId}`)
      let res = await axios.post(`/admin/station/save`, item, {
        headers: {
          'x-auth-token': this.userInfo.token
        }
      })

      // this.showDialog = false
      this.isEditing = false

      await this.loadStations()
    },

    onCloseClicked() {
      this.isEditing = false
    },

    createNewStation() {
      this.itemToEdit = {} as station
      this.isEditing = true
    }
  }
}
</script>
